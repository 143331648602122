<template>
<div :class="$vuetify.breakpoint.smAndUp ? 'w3-padding-64' :'w3-padding-16'">
  <v-main>
    <v-snackbar  top v-model="snackbar" :timeout="8000" :color="color" class="text-center mt-3">
      <span class="white--text">{{message}}</span>
      <template v-slot:action="{attrs}">
        <v-btn  v-bind="attrs" @click="snackbar = !snackbar"  text class="white--text">
          <v-icon class="text-white">mdi-close</v-icon>
        </v-btn>
      </template>

    </v-snackbar>
    <v-img>
      <v-row class="px-2">
        <v-col cols="12" md="3" lg="4" sm="2" class="w3-hide-small">
          &nbsp;
        </v-col>
        <v-col cols="12" md="6" lg="4" sm="8">
          <div v-if="getBusinessInfo===''" style="margin-top:100px">
            <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

              <v-progress-circular  size="60" indeterminate color="#AB005E"></v-progress-circular>

            </div>
          </div>
          <div v-else>
            <v-card class="form-holder " flat>
              <div  class="rounded-0 px-3 py-4" style="   box-shadow: 0px 6px 25px rgb(0 0 0 / 7%)!important;">
                <div class=" d-flex flex-row  " :class="getBusinessInfo.pagetype!=='default' ? 'justify-center':'' " >
                  <div>
                    <v-avatar size="48" class="elevation-1">
                      <v-img :src="getBusinessInfo.logo"></v-img>
                    </v-avatar>
                  </div>

                  <v-spacer v-if="getBusinessInfo.pagetype!=='default'"></v-spacer>
                  <div class="sectionHeaders ml-3" style="width: 100%!important;">
                    <div v-if="getBusinessInfo.pagetype==='default'" class="py-2">{{getBusinessInfo.bname}}</div>

                    <div class="text-center " v-else style="font-weight: 400!important; font-family: Inter, sans-serif!important">
                      <b class="text-capitalize" style="font-size:16px!important; font-family: Inter, sans-serif!important">{{getBusinessInfo.pagename}}</b>
                      <br>
                      <span style="font-size: 13px!important; font-family: Inter, sans-serif!important"> by <span class="text-capitalize">   {{getBusinessInfo.bname}}</span>
                 </span>
                    </div>
                  </div>

                </div>
              </div>

              <v-card-text class="py-4">
                <p class="text-center topic1" v-if="getBusinessInfo.pagetype==='default'">
                  Welcome to  {{getBusinessInfo.bname}}, please make payment below
                </p>
                <p class="text-center topic1" v-else>
                  {{getBusinessInfo.pagedesc}}
                </p>
                <v-form class="mt-2" ref="payment">
                  <v-row>

                    <v-col cols="12" md="6" sm="6" lg="6">
                      <label>First Name</label>
                      <v-text-field
                          solo
                          flat
                          class="topic mb-n2" style="font-size:14px!important"
                          :rules="[rules.required,rules.length]"
                          v-model="firstname"
                          outlined
                          dense
                          color="#e70a80"



                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" lg="6" class="mt-n6 mt-sm-0 mt-md-0 mt-lg-0">
                      <label>Last Name</label>
                      <v-text-field
                          solo
                          flat
                          class="topic mb-n2" style="font-size:14px!important"
                          :rules="[rules.required,rules.length]"
                          v-model="lastname"
                          outlined
                          dense

                          color="#e70a80"

                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12" lg="12" class="mt-n6">
                      <label>Email Address</label>
                      <v-text-field
                          solo
                          flat
                          type="email"
                          class="topic mb-n2" style="font-size:14px!important"
                          :rules="[rules.required,rules.emailFormat]"
                          v-model="email"
                          outlined
                          dense
                          color="#e70a80"

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" class="mt-n6">
                      <label>Phone Number</label>
                      <v-text-field
                          solo
                          flat
                          type="number"
                          class="topic mb-n2" style="font-size:14px!important"
                          :rules="[rules.required,()=>phone.length===11 || 'Invalid Phone Number']"
                          v-model="phone"
                          outlined
                          dense
                          color="#e70a80"

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" class="mt-n6">
                      <label>Amount</label>
                      <div class="d-flex flex-row ">
                        <div class="" :style="{width:$vuetify.breakpoint.smAndUp ? '30%!important': '40%!important'}">
                          <v-select
                              solo
                              flat
                              :items="currencyOptions"
                              class="topic mb-n2" style="font-size:14px!important"
                              :rules="[()=>!!currency || 'Required']"
                              v-model="currency"
                              type="text"
                              outlined
                              dense
                              small
                              color="#e70a80"

                          ></v-select>
                        </div>
                        <div class="" :style="{width:$vuetify.breakpoint.smAndUp ? '70%!important': '60%!important'}">
                          <v-text-field
                              solo
                              flat
                              :disabled="getBusinessInfo.pagetype!=='default'"
                              class="topic mb-n2" style="font-size:14px!important"
                              :rules="[()=> checkAmount  || `Amount must be ${currency} ${currency==='NGN'? '100' :'1'} or greater`]"
                              v-model="amount"
                              @keyup="financial($event.target.value)"
                              type="number"
                              outlined
                              dense
                              small
                              color="#e70a80"

                          ></v-text-field>
                        </div>
                      </div>
                    </v-col>

                  </v-row>
                </v-form>
                <br>
                <v-btn
                    text
                    :loading="loader"
                    class="payButton"
                    block
                    @click="initializePayment"

                >Make Payment</v-btn>
                <p class="text-center mt-2">
                  <span class="topic1">If you have any question, send an email to</span>
                  <br>
                  <a style="color:#AB005E; text-decoration: underline!important;" :href="`mailto:${getBusinessInfo.bemail}`">{{getBusinessInfo.bemail}}</a>
                </p>

              </v-card-text>
            </v-card>
            <p class="text-center mt-3 ">
              <span class="topic1" style="font-size: 14px!important;">Need a payment link like this? </span>
              <a
                  target="_blank"
                  class="topic1"
                  style="color:#AB005E!important; font-size: 14px!important;
                   text-decoration: underline!important;"
                  :href="'https://businessportal.1app.online/register-start'">Register here</a>
            </p>
          </div>



        </v-col>
      </v-row>
    </v-img>

  </v-main>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "paymentPage",
  data(){
    return {
      firstname:'',
      lastname:'',
      email:'',
      amount: '',
      phone:'',
      rules: {
        required: value => !!value || 'Required',
        length: value => value.length >= 3 || 'Enter  valid details',
        emailFormat: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        passwordFormat: value => {
          const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          return pattern.test(value) || "Invalid Password Format"
        }




      },
      currencyOptions:['NGN', 'USD'],
      currency:'NGN',
      loader:false,
      snackbar:false,
      message:'',
      color:''
    }
  },
  computed:{
    ...mapGetters([
        'getBusinessInfo'
    ]),
    checkAmount(){
      if(this.currency==='NGN')return  parseFloat(this.amount) >=100
      return parseFloat(this.amount)>=1

    }
  },
  methods: {
    async initializePayment() {
      if (this.$refs.payment.validate()) {
        this.loader = true
        await this.$store.dispatch('initiatePayment', {
          amount: this.getBusinessInfo.pagetype === 'default' ? this.amount : this.getBusinessInfo.pageamount,
          fname: this.firstname,
          lname: this.lastname,
          phn: this.phone,
          currency: this.currency,
          email: this.email,
          pagetype: this.getBusinessInfo.pagetype,
          bisid: this.getBusinessInfo.bisid,
          pageid: this.getBusinessInfo.pageid,
        })
            .then(response => response.json())
            .then(async response => {

              if (response.status) {
                sessionStorage.setItem('payment_link', this.$route.params.pagename)

                 await this.$store.commit('updatePaymentProfile',{
                  amount: this.getBusinessInfo.pagetype === 'default' ? this.amount : this.getBusinessInfo.pageamount,
                  fname: this.firstname,
                  lname: this.lastname,
                  phn: this.phone,
                  currency: this.currency,
                  email: this.email,
                  pagetype: this.getBusinessInfo.pagetype,
                  bisid: this.getBusinessInfo.bisid,
                  pageid: this.getBusinessInfo.pageid,
                })
                return await this.$router.push({
                  name: 'checkOut',
                  params: {access_token: response.access_token, reference: response.reference}
                })
              }

              this.loader = false
              this.snackbar = true
              this.message = response.message
              this.color = 'error'

            }).catch(e => console.log(e.message))
      }
    },
    financial (value){
    return  this.amount = (value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3)) : value;
      // return this.amount = Number.parseFloat(this.amount).toPrecision(2)
    },


  },



 async created() {
   if (this.$route.params.pagename === undefined) return await this.$router.push({name: 'home'})

   await this.$store.dispatch('fetchBusinessInfo', this.$route.params.pagename)

   if (this.getBusinessInfo.pagetype === 'custom') return this.amount = this.getBusinessInfo.pageamount
   /* this.$watch('amount', (newAmount)=>{
     console.log(newAmount)
      this.amount =   this.financial(newAmount)
   })*/
 }
}
</script>

<style scoped>
html,body{
  background: #E5E5E5!important;
}
.cards{
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%)!important;
  border: 0 solid rgba(0,0,0,.125)!important;
  border-radius: 1rem!important;
}
.sectionHeaders{
  font-size: 1.1rem;
  line-height: 1.625;
  color: #344767;
  font-weight: 600;
  font-family: Inter, sans-serif!important;

}
.NqanGvcWiCU6BArN5gOD {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  width: 105px;
  height: 105px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-in;
  pointer-events: none;
  will-change: transform;
}

element.style {
}
.PXlarHSsuCPnwMqPtjzN {
  position: relative;
  top: 21px;
  left: -11px;
  z-index: 1;
  display: block;
  width: 160px;
  padding: 9px 0 6px 0;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  background-color: #ab005e;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  transform: rotate( 45deg);
  transition: all 0.1s ease-in;
  pointer-events: auto;
}
.HfwXc2kW0vSgTdBa0sD6 {
  margin-left: -7px;
  color: #F2F2F2;
  font-size: 8px;
  text-transform: uppercase;
}
.sv3atfFjLzu9yiAmaUZk {
  margin-left: -7px;
  color: #ffffff;
  font-size: 14px;
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  right: 0;
  bottom: 0;
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before, .NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before {
  top: 0;
  left: 0;
}
.topic1{
  line-height: 1.625!important;
  font-weight: 400!important;
  font-size: 12px!important;
  margin-top: 0!important;
  margin-bottom: 1rem!important;
  color: #444!important;
  font-family: Inter, sans-serif!important;
}
label{
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #67748e;
  text-align: center!important;
  font-family: Inter, sans-serif!important;
}
.payButton{
  background: #AB005E!important;
  border-color: #AB005E!important;
  color: #fff!important;
  width: 150px!important;
  padding: 0.6rem 1rem!important;
  font-size: 13px!important;
  opacity: 1!important;
  border-radius: 3px!important;
  font-weight: 400!important;
}
.form-holder{


  background-color: white;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 24%) -24px 24px 72px -8px;
  line-height: 1.6!important;
}
</style>